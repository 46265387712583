.App {
  text-align: center;
}

@media (min-width: 576px) {
  .modal-right {
    margin-right: 20px !important;
  }
}

.btn-task-action {
  border-radius: 25px !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  padding-left: 3% !important;
  padding-right: 3% !important;
  background: rgb(0, 8, 255, 0.8) !important;
  border-color: rgb(0, 8, 255, 0.8) !important;
}

.btn-task-action:hover {
  border-radius: 25px !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  padding-left: 3% !important;
  padding-right: 3% !important;
  background: rgb(0, 8, 255, 0.7) !important;
  border-color: rgb(0, 8, 255, 0.7) !important;
}

.social-link {
  color: black;
}

.social-link:hover {
  color: rgb(0, 8, 255);
}

.footer-text-decorate {
  text-decoration: underline;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: start;
}

table td,
table th {
  padding: 5px;
}

.home-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(4, 2, 25, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: clamp(0.5rem, 1rem, 1.5rem);
  text-align: center;
  height: 100%;
  opacity: 1;
}

.banner-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(4, 2, 25, 0.8);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: clamp(0.5rem, 1rem, 1.5rem);
  text-align: center;
  height: 100%;
  opacity: 1;
}

.banner-image {
  width: 100%;
  height: 900px;
}

.banner-heading {
  animation: fadeIn 3s ease;
  animation-iteration-count: 1;
  font-size: clamp(2.5rem, 5vw, 5rem) !important;
}

.banner-subheading {
  color: white !important;
  font-size: clamp(1.2rem, 2vw, 3rem);
  font-weight: bold;
  animation: fadeIn 3s ease;
  animation-iteration-count: 1;
}

.banner-description {
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  font-weight: 500;
  animation: fadeIn 3s ease;
  animation-iteration-count: 1;
}

.btn-banner {
  color: white !important;
  font-size: clamp(1rem, 1vw, 1.5rem) !important;
  animation: fadeIn 3s ease;
  animation-iteration-count: 1;
}

.btn-banner:hover {
  color: white !important;
  font-size: clamp(1rem, 1vw, 1.5rem) !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
    margin-left: -100%;
  }

  100% {
    opacity: 100%;
    margin-right: 0%;
  }
}

.home-label {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: clamp(1.5rem, 3vw, 3rem) !important;
}

.slider-label {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: clamp(1.5rem, 3vw, 3rem) !important;
}

.slider-label1 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.home-card-title {
  font-size: clamp(1.2rem, 2vw, 3rem);
}

.home-quote {
  font-size: clamp(1rem, 1.2vw, 2rem);
  font-weight: 500;
}

#template-mo-jassa-hero-carousel .carousel-indicators li {
  margin-top: -50px;
  background-color: #fff;
}

.carousel-indicators button {
  background-color: red;
}

.carousel-control {
  display: none;
}

.carousel-indicators [data-bs-target] {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
}

.carousel-inner {
  padding: 0 0%;
}

.carousel-inner h1 {
  font-weight: 800;
  font-size: 70px;
  line-height: 100px;
  color: #FFFFFF;
  letter-spacing: -2px;
  font-weight: bold !important;
}

.carousel-inner h3,
.carousel-inner p {
  color: #fff;
}

.carousel-indicators {
  display: none !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  visibility: hidden !important;
}

.carousel-inner a {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;

  color: #FFFFFF;
}

.carousel-inner {
  height: auto;
}

.carousel-inner h1 {
  line-height: 150%;
  letter-spacing: 2px;
}

.home-page-info h3 {
  background: linear-gradient(180deg, rgb(0, 8, 255, 0.4), rgb(255, 255, 255));
  color: black;
  padding: 2%;
  font-weight: 600;
  font-family: sans-serif;
  font-size: larger;
}

.services-row {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 5%;
  width: 100%;
}

.services-column {
  padding-top: 2%;
  padding-bottom: 2%;
}

.services-column:hover {
  padding-top: 2%;
  padding-bottom: 2%;
  animation: movecard 3s ease;
  animation-iteration-count: infinite;
}

@keyframes movecard {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.project-sample {
  width: 50%;
  border-radius: 5%;
  padding: 1%;
}

.project-feedback {
  width: 33%;
  border-radius: 5%;
  padding: 1%;
}

@media (max-width:800px) {
  .project-feedback {
    width: 50%;
    border-radius: 5%;
    padding: 1%;
  }
}

.project-page-info {
  padding: 2%;
  font-weight: 500;
  font-family: sans-serif;
  font-size: x-large;
}

.service-page-info {
  background: rgb(0, 8, 255, 0.6);
  color: white;
  padding: 2%;
  font-family: sans-serif;
  font-size: clamp(1rem, 1.5vw, 2.5rem);
}

.fixed-banner-bg {
  background-image: url("./images/banner-image.jpg");
  min-height: 800px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fixed-banner-bg2 {
  background-image: url("./images/banner2.jpg");
  min-height: 800px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width : 850px) {
  .fixed-banner-bg {
    background-image: url("./images/banner-image.jpg");
    min-height: 650px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fixed-banner-bg2 {
    background-image: url("./images/banner2.jpg");
    min-height: 650px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width : 850px) {
  .fixed-banner-bg {
    background-image: url("./images/banner1.jpg");
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fixed-banner-bg2 {
    background-image: url("./images/banner2.jpg");
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fixed-banner-bg2-image {
    height: 225px;
  }
}

.header-banner {
  height: 400px !important;
}

@media (max-width : 650px) {
  .fixed-banner-bg {
    background-image: url("./images/banner1.jpg");
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fixed-banner-bg2 {
    background-image: url("./images/banner2.jpg");
    min-height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .banner-heading {
    animation: none !important;
    font-size: clamp(2rem, 3.5vw, 4rem) !important;
  }

  .banner-subheading {
    color: white !important;
    font-size: clamp(1.3rem, 2vw, 3rem);
    color: transparent !important;
    font-weight: bold;
    animation: none !important;
  }

  .banner-description {
    font-size: clamp(0.7rem, 1.5vw, 1.5rem);
    font-weight: 500;
    animation: none !important;
  }

  .btn-banner {
    color: white !important;
    font-size: clamp(1rem, 1vw, 1.5rem) !important;
    animation: none !important;
  }

  .fixed-banner-bg2-image {
    height: 125px;
  }

  .header-banner {
    height: 200px !important;
  }
}

@media (max-width : 450px) {
  .fixed-banner-bg {
    background-image: url("./images/banner1.jpg");
    min-height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fixed-banner-bg2 {
    background-image: url("./images/banner2.jpg");
    min-height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .banner-heading {
    animation: none !important;
    font-size: clamp(1.5rem, 3.5vw, 4rem) !important;
  }

  .banner-subheading {
    color: white !important;
    font-size: clamp(1.1rem, 2vw, 3rem);
    color: transparent !important;
    font-weight: bold;
    animation: none !important;
  }

  .banner-description {
    font-size: clamp(0.7rem, 1.5vw, 1.5rem);
    font-weight: 500;
    animation: none !important;
  }

  .btn-banner {
    color: white !important;
    font-size: clamp(1rem, 1vw, 1.5rem) !important;
    animation: none !important;
  }

  .fixed-banner-bg2-image {
    height: 125px;
  }

  .header-banner {
    height: 200px !important;
  }
}

.about-info-img {
  width: 100%;
  box-shadow: 2px 2px 20px 5px lightgrey;
}

.btn-contact {
  width: 100%;
  background: rgb(0, 8, 255, 0.8) !important;
  border-color: rgb(0, 8, 255, 0.8) !important;
  border-radius: 25px !important;
}

.btn-contact:hover {
  width: 100%;
  background: rgb(0, 8, 255, 0.7) !important;
  border-color: rgb(0, 8, 255, 0.7) !important;
  border-radius: 25px !important;
}

.contact-page-info {
  padding: 5% 20%;
  background: #ededed;
}

.home-info-img {
  width: 100%;
  border-radius: 5px;
  margin-left: 5%;
  margin-top: 5%;
}

.home-page-row {
  padding: 5% 20%;
  width: 98%;
  text-align: start;
}

.project-row {
  background: #a1a1a145;
  width: 100%;
}

.top-to-btm {
  position: relative;
}

.npicon-style {
  background-color: rgb(98, 200, 240);
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
}

.icon-position {
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 20;
}

.icon-style {
  background-color: rgb(0, 8, 255, 0.8);
  border: 2px solid rgb(0, 8, 255, 0.8);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: rgb(0, 8, 255, 0.8);
  border: 2px solid rgb(0, 8, 255, 0.8);
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.card-body {
  padding: 0.5rem !important;
  align-self: center !important;
  width: 100% !important;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 8, 255, 0.4);
  background: rgb(0, 8, 255, 0.4);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.card:hover .overlay {
  opacity: 1;
}

.btn-view {
  width: 100%;
  border-radius: 20px !important;
  background: rgb(0, 8, 255, 0.8) !important;
  border-color: rgb(0, 8, 255, 0.8) !important;
}

.btn-subscribe {
  width: 100%;
  border-radius: 20px !important;
  background: rgb(0, 8, 255, 0.8) !important;
  border-color: rgb(0, 8, 255, 0.8) !important;
}

.btn-subscribe:hover {
  width: 100%;
  border-radius: 20px !important;
  background: rgb(0, 8, 255, 0.7) !important;
  border-color: rgb(0, 8, 255, 0.7) !important;
}

.btn-sample {
  width: 80%;
  border-radius: 20px !important;
  background: rgb(0, 8, 255, 0.8) !important;
  border-color: rgb(0, 8, 255, 0.8) !important;
  position: absolute;
  top: 80%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn-sample:hover {
  background: rgb(0, 8, 255, 0.7) !important;
  border-color: rgb(0, 8, 255, 0.7) !important;
}

.inputbox:focus {
  border-color: white !important;
  box-shadow: 0 0 0 0.15rem rgb(0, 8, 255, 0.4) !important;
}

.btn-payment {
  background: rgb(0, 8, 255, 0.7) !important;
  width: 103% !important;
  color: white !important;
  font-weight: 500 !important;
  border: 0.5px solid rgb(0, 8, 255, 0.7) !important;
}

.card {
  border: 1px solid rgb(240, 240, 240) !important;
  font-size: 1.2rem;
  font-weight: 500;
  color: rgb(0, 8, 255, 0.7);
  transition: .3s ease-in-out;
}

.card:hover {
  box-shadow: 2px 2px 10px 5px lightgrey;
  transition: .3s ease-in-out;
  cursor: pointer;
}

.services-listing {
  width: 100%;
  padding: 1% 10% 1% 10%;
  text-align: center;
}

.blog-title {
  text-align: start;
  color: black !important;
  text-decoration: none !important;
}

.blog-title:hover {
  color: rgb(0, 8, 255, 0.6) !important;
}

.blog-content {
  font-family: sans-serif;
  text-align: start;
}

.blog-content img {
  width: 100%;
  height: 100%;
}

.blog-content a {
  color: rgb(0, 8, 255, 0.6) !important;
  text-decoration: underline !important;
  text-decoration-color: rgb(0, 8, 255, 0.6);
}

.featured-image {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.featured-image img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);
}

.featured-image:hover img {
  transform: scale(1.1);
  opacity: 0.8;
}

.blogs,
.specific-blog {
  padding-left: 15%;
  padding-right: 15%;
  margin-bottom: 5%;
}

.blog {
  color: black !important;
  box-shadow: 2px 2px 10px 5px lightgrey;
  margin-top: 5%;
}

.specific-blog-title {
  text-transform: uppercase;
  font-family: monospace;
}

.blog-text {
  padding: 1.5% 5% 1.5% 5%;
}

.blog-text img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.keywords {
  display: flex;
  padding: 1% 5% 1% 5%;
}

.blog-keywords {
  padding: 1%;
  background-color: rgb(0, 8, 255, 0.6);
  color: white;
  font-size: 15px;
  margin-right: 2%;
  border-radius: 3px;
  font-weight: 700;
}

.blog-keywords:hover {
  transition: all .5s ease-in-out;
  background-color: rgb(6, 3, 29);
  color: white !important;
}

.custom-spinner {
  margin-left: 50%;
  margin-right: 50%;
  margin-top: 10%;
}

.blog-comments {
  background: #d3d3d385;
  padding: 10px;
  opacity: 0.8;
  border-radius: 5px;
  font-size: 1rem;
}

.comment {
  background-color: #d3d3d34d;
  padding: 0% 5% 2% 5% !important;
  opacity: 0.85;
  font-size: 1.2rem;
  width: 82.5% !important;
  border-radius: 25px;
}

.review {
  background-color: #d3d3d34d;
  padding: 0% 5% 2% 5% !important;
  opacity: 0.85;
  font-size: 1.2rem;
  width: 75% !important;
  border-radius: 25px;
}

@media (max-width: 925px) {
  .review {
    background-color: #d3d3d34d;
    padding: 0% 5% 2% 5% !important;
    opacity: 0.85;
    font-size: 1.2rem;
    width: 100% !important;
    border-radius: 25px;
  }
}

.reply-comment {
  padding: 0% 5% 2% 5% !important;
  opacity: 0.85;
  font-size: 1.2rem;
  width: 82.5% !important;
  border-radius: 25px;
  border: 1px solid lightgrey;
}

.comment-reply {
  color: rgb(0, 8, 255, 0.6);
  text-align: start;
}

.btn-reply {
  color: rgb(0, 8, 255, 0.6) !important;
  border: 2px solid transparent !important;
  padding: 0% !important;
}

.categories {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 0rem !important;
  padding-left: 4.5% !important;
}

.category {
  float: left;
}

.category span {
  display: block;
  color: white;
  text-align: center;
  padding: 10px;
  background-color: rgb(0, 8, 255, 0.8);
  margin: 5px;
  border-radius: 5px;
  font-weight: 500;
}

.category span:hover {
  background-color: rgb(0, 8, 255, 0.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Nunito", sans-serif;
  background: #fbf7f4;
}

.counter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.counter {
  text-align: center;
}

.counter h3 {
  padding: 0.5rem 0;
  font-size: 2.5rem;
  font-weight: 800;
}

.counter h6 {
  font-size: 2rem;
  padding-bottom: 1rem;
}

.count {
  color: rgb(0, 8, 255, 0.8);
}

.btn-expert {
  background: rgb(0, 8, 255, 0.8) !important;
  font-size: larger !important;
  border-color: rgb(0, 8, 255, 0.8) !important;
}

.btn-expert:hover {
  background: rgb(0, 8, 255, 0.65) !important;
  border-color: rgb(0, 8, 255, 0.65) !important;
}

.btn-review {
  background: rgb(0, 8, 255, 0.8) !important;
  border-color: rgb(0, 8, 255, 0.8) !important;
}

.btn-review:hover {
  background: rgb(0, 8, 255, 0.7) !important;
  border-color: rgb(0, 8, 255, 0.7) !important;
}

.specialization {
  padding: 2% 10%;
  width: 100%;
}

.specialization span {
  font-size: 1.2rem;
  font-weight: 500;
}

.error-text {
  color: rgb(0, 8, 255, 0.8);
  text-shadow: 0.9rem 0.8rem 0 #eaeaea;
  font-size: 10rem;
}

.btn-task {
  background-color: rgb(0, 8, 255, 0.8) !important;
  color: white !important;
  font-size: inherit !important;
}

.btn-task:hover {
  background-color: rgb(0, 8, 255, 0.7) !important;
  color: white !important;
  font-size: inherit !important;
}

.login-form {
  padding: 10% 30%;
  text-align: start;
}

.hover-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.7);
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.card:hover .hover-overlay {
  opacity: 1;
}

.react-pdf__Page {
  margin: 0px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  height: 0px !important;
  width: 0px !important;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}